import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC, useMemo } from 'react';
import { ProjectGroupBaseDTO } from '@mottmac-moata/identity-sdk';
import { TableLoadingSkeleton } from 'src/features/admin/components/LoadingSkeleton';
import { Result, NoSearchResult, FetchErrorResult } from 'src/ui/Result';
import { ProjectGroupAPI } from 'src/services/api/ProjectGroupAPI';
import { HeaderCell } from 'src/features/admin/components/HeaderCell';
import { HeaderSortLabel } from 'src/features/admin/components/HeaderSortLabel';
import { useSortByProperties } from 'src/hooks/useSortByProperties';

interface ProjectUserGroupsTableProps {
  userGroupIds?: string[];
  projectId: number;
  userId: string;
  filterValue?: string;
}

export const ProjectUserGroupsTable: FC<ProjectUserGroupsTableProps> = ({
  projectId,
  filterValue,
  userGroupIds = [],
}) => {
  const { data: groups, isLoading, error } = ProjectGroupAPI.useGetByProjectId(Number(projectId));

  const filteredGroups = useMemo(
    () =>
      groups?.filter(
        (group) =>
          userGroupIds.includes(group?.groupId) && group?.name?.toLowerCase().includes(filterValue?.toLowerCase() ?? '')
      ),
    [userGroupIds, groups, filterValue]
  );

  const {
    sorted: filteredAndSortedGroups,
    sort: sortGroups,
    getIsActive,
    getDirection,
  } = useSortByProperties<ProjectGroupBaseDTO>(filteredGroups);

  const isErrorState = !isLoading && error && !groups;
  const isNoDataState = !isLoading && !error && !groups?.length;
  const isNoSearchResulttate = !isErrorState && !isNoDataState && !!filterValue && !filteredAndSortedGroups?.length;
  const isAbnormalState = isErrorState || isNoSearchResulttate || isNoDataState;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="Table of user's groups">
        <TableHead>
          <TableRow>
            <HeaderCell>
              <HeaderSortLabel
                active={getIsActive(['name'])}
                direction={getDirection(['name'])}
                onClick={() => sortGroups(['name'])}
              >
                GROUPS{filteredAndSortedGroups ? ` (${filteredAndSortedGroups.length})` : ''}
              </HeaderSortLabel>
            </HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isAbnormalState && (
            <TableRow>
              <TableCell colSpan={2}>
                {isNoDataState && <Result severity="info" title="This user is not added to any group" />}
                {isNoSearchResulttate && <NoSearchResult />}
                {isErrorState && <FetchErrorResult />}
              </TableCell>
            </TableRow>
          )}

          {isLoading && <TableLoadingSkeleton columns={1} />}

          {filteredAndSortedGroups?.map(({ name }) => {
            return (
              <TableRow key={name} sx={{ '&:nth-of-type(odd)': { backgroundColor: 'divider' } }}>
                <TableCell>
                  <CardHeader avatar={<Avatar>{name?.[0]?.toUpperCase()}</Avatar>} title={name} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
