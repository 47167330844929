import { jwtDecode } from 'jwt-decode';

// https://dev.azure.com/mmdigitalventures/Moata/_wiki/wikis/Module%20-%20Identity/4475/Access-token-details
interface IdentityJwtPayload {
  /**
   * User Id
   */
  sub: string;
  iss: string;
  aud: string;
  iat: number;
  nbf: number;
  exp: number;
}

export interface IdentityAccessTokenPayload extends IdentityJwtPayload {
  /**
   * User email at the first array item
   */
  emails: string[];
  /**
   * Display name
   */
  name?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  given_name?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  family_name?: string;
}

export const decodeJwtToken = jwtDecode<IdentityJwtPayload>;

export const decodeAccessToken = jwtDecode<IdentityAccessTokenPayload>;

export const getJwtExpiresAt = (jwt: string): number => {
  const { exp } = decodeJwtToken(jwt);
  return exp * 1000;
};

export const getJwtExpDate = (jwt: string): Date => {
  const expMs = getJwtExpiresAt(jwt);
  return new Date(expMs);
};
