import { FC, MouseEvent, useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { AccountCircleOutlined } from '@mui/icons-material';
import useGetScreenSize from 'src/hooks/useGetScreenSize';
import { GTMEvents, setGTMDataLayerVariables, trackGTMEvent } from 'src/services/telemetry/gtm';
import { useAccessTokenPayload } from 'src/hooks/useAccessTokenPayload';
import StdUserMenu from './StdUserMenu';
import XsUserMenu from './XsUserMenu';

const UserMenu: FC = () => {
  const { isSm } = useGetScreenSize('up');

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const openMenu = (e: MouseEvent<HTMLElement>): void => setAnchorElement(e.currentTarget);
  const closeMenu = (): void => setAnchorElement(null);

  const payload = useAccessTokenPayload();
  useEffect(() => {
    if (payload) setGTMDataLayerVariables({ id: payload.sub, email: payload.emails[0] });
  }, [payload]);

  return (
    <>
      <IconButton
        size="small"
        onClick={(e) => {
          trackGTMEvent(GTMEvents.header.accountButtonClick());
          openMenu(e);
        }}
        title="Account"
        sx={{ color: 'action.active' }}
        data-gtm-current-user={payload?.emails[0] ?? ''}
        data-gtm-current-user-id={payload?.sub ?? ''}
      >
        <AccountCircleOutlined fontSize="small" />
      </IconButton>
      {isSm ? (
        <StdUserMenu anchorElement={anchorElement} handleClose={closeMenu} />
      ) : (
        <XsUserMenu anchorElement={anchorElement} handleClose={closeMenu} />
      )}
    </>
  );
};

export default UserMenu;
