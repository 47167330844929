import { UserSimpleDTO } from '@mottmac-moata/identity-sdk';
import { ProjectUserAPI } from 'src/services/api/ProjectUserAPI';
import { AdminItemType } from 'src/features/admin/types';
import { ProductGroupAPI } from 'src/services/api/ProductGroupAPI';

export const useGetGroupUsers = (
  itemType: AdminItemType,
  itemId: number,
  groupId: string
): { isLoading: boolean; isError: boolean; users?: UserSimpleDTO[] } => {
  const {
    data: projectGroupUsers,
    isLoading: isLoadingProjectGroupUsers,
    isError: isProjectGroupUsersError,
  } = ProjectUserAPI.useGetByGroupId(itemId, groupId, { enabled: itemType === AdminItemType.Project });
  const {
    data: productGroupUsers,
    isLoading: isLoadingProductGroupUsers,
    isError: isProductGroupUsersError,
  } = ProductGroupAPI.useGetUsers(itemId, groupId, { enabled: itemType === AdminItemType.Product });

  return {
    users: itemType === AdminItemType.Project ? projectGroupUsers : productGroupUsers,
    isLoading: isLoadingProjectGroupUsers || isLoadingProductGroupUsers,
    isError: isProjectGroupUsersError || isProductGroupUsersError,
  };
};
