import { PolicyMetadataResult } from '@mottmac-moata/identity-sdk';
import { identityApiClient } from 'src/services/api/identityApiClient';

const getPolicyMetadata = async (
  email: string,
  signal: AbortSignal
): Promise<PolicyMetadataResult & { idpLogoutUrl?: string; policy: string }> => {
  const { metadataAddress, idpLogoutUrl } = await identityApiClient.User.getLoginPolicy({ email }, { signal });
  const metadata = await identityApiClient.Policy.getMetadata({ metadataAddress }, { signal });

  return {
    ...metadata,
    idpLogoutUrl,
    policy: metadata.authorization_endpoint.split('p=')[1],
  };
};

export default getPolicyMetadata;
