import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { AppFullRoutePath, AppPage, LoginPageSearchParam } from 'src/router';
import { B2cStatePayload, decodeB2cStatePayload } from 'src/store/auth/utils/b2cStatePayload';
import { isAbsoluteUrl, isAllowedMoataUrl, isAppAbsoluteUrl, mergeHashParamsToUrl } from 'src/utils/url';
import { isAbortError } from 'src/utils/errors';
import { CallbackRedirectIdTokenParam, CallbackRedirectStateParam } from '../constants';
import useCallbackRedirectAuthParams from './useCallbackRedirectParams';

const getRedirectPayload = (
  urlSearchParams: URLSearchParams,
  page: AppPage.Login | AppPage.Callback
): B2cStatePayload => {
  if (page === AppPage.Login) {
    return {
      callbackUri: urlSearchParams.get(LoginPageSearchParam.CallbackUri) ?? undefined,
      state: urlSearchParams.get(LoginPageSearchParam.State) ?? undefined,
    };
  }

  if (page === AppPage.Callback) {
    const stateParam = urlSearchParams.get('state') ?? undefined;
    return decodeB2cStatePayload(stateParam) ?? {};
  }

  return {};
};

type RedirectHandler = (
  params: { page: AppPage.Login | AppPage.Callback },
  options?: { signal?: AbortSignal }
) => Promise<void>;

export const useRedirectSearchParamsHandler = (): {
  handle: RedirectHandler;
} => {
  const navigate = useNavigate();
  const { isAuthenticated, getAccessToken } = useAuth();
  const authParams = useCallbackRedirectAuthParams();
  const [urlSearchParams] = useSearchParams();

  const handle = useCallback<RedirectHandler>(
    async ({ page }, { signal } = {}) => {
      if (!isAuthenticated) return;

      const { callbackUri, state } = getRedirectPayload(urlSearchParams, page);

      // Moata sub-app redirect
      if (callbackUri && isAllowedMoataUrl(callbackUri)) {
        try {
          const token = await getAccessToken(signal);
          const redirectParams = {
            ...authParams,
            [CallbackRedirectStateParam.State]: state ?? '',
            // put id_token at the end as it's not a standard param for auto-login redirect
            [CallbackRedirectIdTokenParam.IdToken]: token ?? '',
          };
          const newUrl = mergeHashParamsToUrl(callbackUri, redirectParams);
          window.location.replace(newUrl);
          return;
        } catch (error) {
          if (isAbortError(error)) return;
          // only suspend the AbortedError for now, as we don't have a screen to handle all other errors yet.
          throw error;
        }
      }

      // Identity in-app redirect
      if (!callbackUri && state && (!isAbsoluteUrl(state) || isAppAbsoluteUrl(state))) {
        navigate(state, { replace: true });
        return;
      }

      // invalid or non-allowed url
      navigate(AppFullRoutePath[AppPage.Home], { replace: true });
    },
    [authParams, getAccessToken, isAuthenticated, navigate, urlSearchParams]
  );

  return { handle };
};
