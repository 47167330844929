import { MoataIdentityAPI } from '@mottmac-moata/identity-sdk';
import { API_BASE_URL } from '../../configs';

const defaultConfigs: ConstructorParameters<typeof MoataIdentityAPI>[0] = {
  baseUrl: API_BASE_URL,
  apiToken: undefined,
};

export const identityApiClient = new MoataIdentityAPI(defaultConfigs);

export const authorisedIdentityApiClient = (accessToken: string): MoataIdentityAPI =>
  new MoataIdentityAPI({ ...defaultConfigs, apiToken: accessToken });
