import { UserSimpleDTO } from '@mottmac-moata/identity-sdk';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { FC } from 'react';
import { RouteBuilder } from 'src/router';

interface ProjectUserNameCellProps {
  user: UserSimpleDTO;
  projectId: number;
}

const getAvatarContent = (user: UserSimpleDTO): string =>
  (user?.displayName || user?.email)?.substring(0, 1).toUpperCase();

const NameAndEmail: FC<{ name: string; email: string }> = ({ name, email }) => (
  <Stack>
    <span>{name}</span>
    <span>{email}</span>
  </Stack>
);

export const ProjectUserNameCell: FC<ProjectUserNameCellProps> = ({ user, projectId }) => (
  <TableCell>
    <Box display="flex" alignItems="center" columnGap={1}>
      <Avatar>{getAvatarContent(user)}</Avatar>
      {user.userState !== 'PENDING' ? (
        <Link
          to={`/${RouteBuilder.projectAdminUser(projectId, user.userId)}`}
          component={RouterLink}
          underline="always"
          sx={{ color: 'text.primary' }}
        >
          <NameAndEmail name={user.displayName} email={user.email} />
        </Link>
      ) : (
        <NameAndEmail name={user.displayName} email={user.email} />
      )}
    </Box>
  </TableCell>
);
