import { SerializedError } from '@reduxjs/toolkit';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { UserSimpleDTO } from '@mottmac-moata/identity-sdk';
import { QUERY_KEY_VARIANTS } from 'src/services/api/constants';
import useAuth from 'src/hooks/useAuth';
import { authorisedIdentityApiClient } from 'src/services/api/identityApiClient';
import { PRODUCT_USER_BASE_QUERY_KEY } from './constants';

export const ProductUserAPI = {
  queryKeys: {
    base: PRODUCT_USER_BASE_QUERY_KEY,
    byProductId: (productId: number) => [PRODUCT_USER_BASE_QUERY_KEY, productId] as QueryKey,
    list: (productId: number) =>
      [...ProductUserAPI.queryKeys.byProductId(productId), QUERY_KEY_VARIANTS.LIST] as QueryKey,
  },

  useGetAll: (productId: number, options?: UseQueryOptions<UserSimpleDTO[], SerializedError>) => {
    const { getAccessToken } = useAuth();

    return useQuery(
      ProductUserAPI.queryKeys.list(productId),
      async ({ signal }) =>
        authorisedIdentityApiClient(await getAccessToken()).ProductUser.getAllByProductId(productId, { signal }),
      options
    );
  },
};
