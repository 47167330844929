import { ProductGroupDTO, ProjectGroupDTO } from '@mottmac-moata/identity-sdk';
import { AdminItemType } from 'src/features/admin/types';
import { ProjectGroupAPI } from 'src/services/api/ProjectGroupAPI';
import { ProductGroupAPI } from 'src/services/api/ProductGroupAPI';

export const useGroupUpdate = (
  itemType: AdminItemType,
  itemId: number,
  group: ProjectGroupDTO | ProductGroupDTO
): { updateName: (name: string) => Promise<void> } => {
  const { mutateAsync: mutateProjectGroup } = ProjectGroupAPI.useUpdate();
  const { mutateAsync: mutateProductGroup } = ProductGroupAPI.useUpdate();

  const updateName = async (name: string): Promise<void> => {
    if (itemType === AdminItemType.Project) {
      await mutateProjectGroup({ ...group, projectId: itemId, payload: { name } });
    } else {
      await mutateProductGroup({ ...group, productId: itemId, payload: { name } });
    }
  };

  return { updateName };
};
