import { ProjectUserDTO } from '@mottmac-moata/identity-sdk';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { ChangeEvent, FC, useCallback } from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { ItemsManagementTextConstants } from 'src/features/admin/components/ItemsListManageModal/types';
import { openAreYouSureConfirmDialog } from 'src/ui/Dialog';
import { ProjectUserAPI } from 'src/services/api/ProjectUserAPI';
import { setMessage } from 'src/store/messages';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { ItemsListManageModalButton } from 'src/features/admin/components/ItemsListManageModal/ItemsListManageModalButton';
import { useProjectUserGroupController } from './hooks/useProjectUserGroupController';

const itemsManagementConstants: ItemsManagementTextConstants = {
  title: 'Manage user groups',
  membersTitle: 'Groups of this user',
  actionBtnTooltipTitle: 'Add selected groups to the user',
  removeBtnTooltipTitle: 'Remove selected groups from the user',
  allItemsTitle: 'All groups',
};

interface ProjectUserConfigurationHeaderProps {
  projectId: number;
  user: ProjectUserDTO;
}

export const ProjectUserConfigurationHeader: FC<ProjectUserConfigurationHeaderProps> = ({ projectId, user }) => {
  const dispatch = useAppDispatch();
  const UserGroupsController = useProjectUserGroupController(projectId, user.id);
  const { mutateAsync: grantAdmin, isLoading: isGrantAdminLoading } = ProjectUserAPI.useGrantAdmin();

  const { mutateAsync: revokeAdmin, isLoading: isRevokeAdminLoading } = ProjectUserAPI.useRevokeAdmin();

  const onAdminToggle = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const isGrant = event.target.checked;
      openAreYouSureConfirmDialog({
        warningText: `${isGrant ? 'grant' : 'revoke'} admin privileges for this user`,
        errorText: 'admin privileges change',
        onConfirm: async () => {
          if (isGrant) {
            await grantAdmin({ projectId, userId: user.id });
          } else {
            await revokeAdmin({ projectId, userId: user.id });
          }
          dispatch(
            setMessage({
              severity: isGrant ? 'success' : 'info',
              content: `Admin privileges have been ${isGrant ? 'granted' : 'revoked'}`,
            })
          );
        },
      });
    },
    [dispatch, grantAdmin, projectId, revokeAdmin, user.id]
  );

  return (
    <Grid container px={2}>
      <Grid item xs={12} md={10} alignItems="center">
        <Grid container spacing={{ xs: 1, sm: 3 }} direction={{ xs: 'column', sm: 'row' }} alignItems="center">
          <Grid item>
            <Typography
              component="h1"
              variant="h4"
              sx={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', wordWrap: 'none' }}
            >
              {user?.displayName}
            </Typography>
          </Grid>
          <Grid item xs={4} md={4}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    disabled={isGrantAdminLoading || isRevokeAdminLoading}
                    checked={user.isAdmin}
                    onChange={onAdminToggle}
                  />
                }
                label="Admin"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={2}>
        <Grid container spacing={1} direction={{ xs: 'column', sm: 'row' }}>
          <Grid item xs={12}>
            <ItemsListManageModalButton
              disabled={isGrantAdminLoading || isRevokeAdminLoading}
              title="Manage Groups"
              ItemsController={UserGroupsController}
              constants={itemsManagementConstants}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
