export const EXTERNAL_LINK_TITLE_TEMPLATE = `Open {linkName} in a new window` as const;

export const DATE_FORMAT_DDMMYYYY = 'DD/MM/YYYY';

// Be aware of this when changing them:
// These threshold numbers are used accoss all Moata Apps. Same definitions are also in the "React Moata Identity Internal" package.
export const TOKEN_EXPIRY_THRESHOLD_IN_MS = 2 * 60 * 1000;
export const REFRESH_TOKEN_THRESHOLD_IN_MS = TOKEN_EXPIRY_THRESHOLD_IN_MS + 3 * 60 * 1000;

/**
 * For cases like delayed loading spinner.
 */
export const MINIMUM_NOTICEABLE_INTERACTION_DELAY_IN_MS = 300;
export const MINIMUM_LOADING_ANIMATION_DURATION_IN_MS = 600;
/**
 * For cases like debounced typing.
 */
export const MAXIMUM_REPEATED_INTERACTION_DURATION_IN_MS = 300;
