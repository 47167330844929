import useCallbackRedirectAuthParams from 'src/features/auth/hooks/useCallbackRedirectParams';
import useAuth from 'src/hooks/useAuth';
import { buildProjectUrl } from './buildProjectUrl';

interface UseProjectUrlReturn {
  getProjectUrl: () => Promise<string | undefined>;
}

export const useProjectUrl = (
  projectSourceUrl: string | undefined,
  projectId?: number,
  projectName?: string,
  productId?: number,
  resourceName?: string
): UseProjectUrlReturn => {
  const { getAccessToken } = useAuth();
  const authParams = useCallbackRedirectAuthParams();

  const getProjectUrl = async (): Promise<string | undefined> => {
    if (!projectSourceUrl) return undefined;

    const token = await getAccessToken();

    return buildProjectUrl(projectSourceUrl, {
      token: `${token}&${new URLSearchParams(authParams).toString()}`,
      projectId,
      projectName,
      productId,
      resourceName,
    });
  };

  return { getProjectUrl };
};
