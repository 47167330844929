import { TOKEN_EXPIRY_THRESHOLD_IN_MS } from './constants';
import { getJwtExpiresAt } from './jwtHelpers';

const hasTokenDateExpired = (expiry: Date | number, thresholdInMs = 0): boolean => {
  return new Date(expiry).valueOf() - thresholdInMs <= Date.now();
};

export const hasAccessTokenExpired = (token: string): boolean => {
  const expiresAt = getJwtExpiresAt(token);
  return hasTokenDateExpired(expiresAt, TOKEN_EXPIRY_THRESHOLD_IN_MS);
};

export const hasRefreshTokenExpired = (expiryInMs: Date | number): boolean => {
  return hasTokenDateExpired(expiryInMs, TOKEN_EXPIRY_THRESHOLD_IN_MS);
};
