import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppFullRoutePath, AppPage, AppRouteSegment } from 'src/router';
import { ProductGroupAdminRouteGuard } from 'src/features/admin/components/GroupAdminRouteGuard/ProductGroupAdminRouteGuard';
import ProductConfigurationPage from 'src/pages/admin/ProductConfigurationPage';
import ProductGroupConfigurationPage from 'src/pages/admin/ProductGroupConfigurationPage';
import { ProjectGroupAdminPageLayout } from 'src/features/admin/components/GroupAdminPageLayout/ProjectGroupAdminPageLayout';
import { ProjectGroupAdminRouteGuard } from 'src/features/admin/components/GroupAdminRouteGuard/ProjectGroupAdminRouteGuard';
import { ProductGroupAdminPageLayout } from 'src/features/admin/components/GroupAdminPageLayout/ProductGroupAdminPageLayout';
import ProductsPage from 'src/pages/admin/ProductsPage';
import ProjectConfigurationPage from '../ProjectConfigurationPage';
import ProjectGroupConfigurationPage from '../ProjectGroupConfigurationPage';
import ProjectUserConfigurationPage from '../ProjectUserConfigurationPage';

export const AdminRouter: FC = () => (
  <Routes>
    <Route path={`${AppRouteSegment.Projects}/${AppRouteSegment.Wildcard}`}>
      <Route
        path={AppRouteSegment.ProjectId}
        element={
          <ProjectGroupAdminRouteGuard>
            <ProjectGroupAdminPageLayout />
          </ProjectGroupAdminRouteGuard>
        }
      >
        <Route path={`${AppRouteSegment.Groups}/${AppRouteSegment.Wildcard}`}>
          <Route path={AppRouteSegment.GroupId} element={<ProjectGroupConfigurationPage />} />
          <Route index element={<ProjectConfigurationPage selectedTab={AppRouteSegment.Groups} />} />
        </Route>

        <Route path={`${AppRouteSegment.Users}/${AppRouteSegment.Wildcard}`}>
          <Route path={AppRouteSegment.UserId} element={<ProjectUserConfigurationPage />} />
          <Route index element={<ProjectConfigurationPage selectedTab={AppRouteSegment.Users} />} />
        </Route>

        <Route path={`${AppRouteSegment.Actions}/${AppRouteSegment.Wildcard}`}>
          <Route index element={<ProjectConfigurationPage selectedTab={AppRouteSegment.Actions} />} />
        </Route>

        <Route path={AppRouteSegment.Wildcard} element={<Navigate to={AppRouteSegment.Groups} replace />} />
      </Route>

      <Route path={AppRouteSegment.Wildcard} element={<Navigate to={AppFullRoutePath[AppPage.Home]} replace />} />
    </Route>

    <Route path={`${AppRouteSegment.Products}/${AppRouteSegment.Wildcard}`}>
      <Route
        path={AppRouteSegment.ProductId}
        element={
          <ProductGroupAdminRouteGuard>
            <ProductGroupAdminPageLayout />
          </ProductGroupAdminRouteGuard>
        }
      >
        <Route path={`${AppRouteSegment.Groups}/${AppRouteSegment.Wildcard}`}>
          <Route path={AppRouteSegment.GroupId} element={<ProductGroupConfigurationPage />} />
          <Route index element={<ProductConfigurationPage selectedTab={AppRouteSegment.Groups} />} />
        </Route>
        <Route path={`${AppRouteSegment.Actions}/${AppRouteSegment.Wildcard}`}>
          <Route index element={<ProductConfigurationPage selectedTab={AppRouteSegment.Actions} />} />
        </Route>
        <Route path={AppRouteSegment.Wildcard} element={<Navigate to={AppRouteSegment.Groups} replace />} />
      </Route>
      <Route path={AppRouteSegment.Wildcard} element={<Navigate to={AppFullRoutePath[AppPage.Home]} replace />} />
      <Route index element={<ProductsPage />} />
      <Route
        path={AppRouteSegment.Wildcard}
        element={<Navigate to={AppFullRoutePath[AppPage.AdminProducts]} replace />}
      />
    </Route>

    <Route path={AppRouteSegment.Wildcard} element={<Navigate to={AppFullRoutePath[AppPage.Home]} replace />} />
  </Routes>
);
