import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC, useMemo } from 'react';
import { UserSimpleDTO } from '@mottmac-moata/identity-sdk';
import { TableLoadingSkeleton } from 'src/features/admin/components/LoadingSkeleton';
import { FetchErrorResult, NoSearchResult, Result } from 'src/ui/Result';
import { makeUserUIKey } from 'src/features/admin/helpers';
import { HeaderCell } from 'src/features/admin/components/HeaderCell';
import { HeaderSortLabel } from 'src/features/admin/components/HeaderSortLabel';
import { useSortByProperties } from 'src/hooks/useSortByProperties';
import { AdminItemType } from 'src/features/admin/types';
import { useGetUsersByGroupId } from 'src/features/admin/hooks/useGetUsersByGroupId';

interface GroupUsersTableProps {
  itemType: AdminItemType;
  itemId: number;
  groupId: string;
  filterValue?: string;
}

export const GroupUsersTable: FC<GroupUsersTableProps> = ({ itemId, groupId, filterValue, itemType }) => {
  const { users, isLoading, error } = useGetUsersByGroupId(itemType, Number(itemId), String(groupId));

  // Remove pending users from the list. We could display
  const activeMembers = useMemo(() => users?.filter(({ userState }) => userState !== 'PENDING'), [users]);

  const filteredUsers = useMemo(
    () =>
      activeMembers?.filter(({ displayName }) => displayName?.toLowerCase().includes(filterValue?.toLowerCase() ?? '')),
    [activeMembers, filterValue]
  );

  const {
    sorted: filteredAndSortedUsers,
    sort: sortUsers,
    getIsActive,
    getDirection,
  } = useSortByProperties<UserSimpleDTO>(filteredUsers);

  const isErrorState = !isLoading && error && !users;
  const isNoDataState = !isLoading && !error && !activeMembers?.length;
  const isNoSearchResultState = !isErrorState && !isNoDataState && !!filterValue && !filteredAndSortedUsers?.length;
  const isAbnormalState = isErrorState || isNoSearchResultState || isNoDataState;

  return (
    <TableContainer component={Paper}>
      <Table aria-label={`Table of members in the ${itemType} group`}>
        <TableHead>
          <TableRow>
            <HeaderCell>
              <HeaderSortLabel
                active={getIsActive(['displayName', 'surname', 'givenName', 'email'])}
                direction={getDirection(['displayName', 'surname', 'givenName', 'email'])}
                onClick={() => sortUsers(['displayName', 'surname', 'givenName', 'email'])}
              >
                USERS{filteredAndSortedUsers ? ` (${filteredAndSortedUsers.length})` : ''}
              </HeaderSortLabel>
            </HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isAbnormalState && (
            <TableRow>
              <TableCell colSpan={2}>
                {isNoDataState && <Result severity="info" title="This group is empty." />}
                {isNoSearchResultState && <NoSearchResult />}
                {isErrorState && <FetchErrorResult />}
              </TableCell>
            </TableRow>
          )}

          {isLoading && <TableLoadingSkeleton columns={1} />}

          {filteredAndSortedUsers?.map((user) => {
            return (
              <TableRow key={makeUserUIKey(user)} sx={{ '&:nth-of-type(odd)': { backgroundColor: 'divider' } }}>
                <TableCell>
                  <CardHeader
                    avatar={<Avatar>{user.displayName[0]}</Avatar>}
                    title={user.displayName}
                    subheader={user.email}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
