/* eslint-disable @rushstack/no-new-null */
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { B2CTokens } from '@mottmac-moata/identity-sdk';

import { AuthState, ExtendedUserProfile } from './types';
import { login, handleCallback, logout } from './async-thunks';
import { AUTH_SLICE_NAME } from './sliceName';

export const initialState = (): AuthState => ({
  policy: null,
  b2cTokens: null,
  user: null,
  idpLogoutUrl: null,
  projectLoginUrl: null,
  projectLogoutUrl: null,
  redirectingToAuthServer: false,
});

const resetState = (state: AuthState): void => {
  state.policy = null;
  state.b2cTokens = null;
  state.user = null;
  state.idpLogoutUrl = null;
  state.redirectingToAuthServer = false;
};

const slice = createSlice({
  name: AUTH_SLICE_NAME,
  initialState: initialState(),
  reducers: {
    setTokens: (state, { payload: b2cTokens }: { payload: B2CTokens }) => {
      state.b2cTokens = b2cTokens;
    },
    resetAuth: resetState,
    clearIdpLogoutUrl: (state) => {
      state.idpLogoutUrl = null;
    },
    setUser: (state, { payload: user }: { payload: ExtendedUserProfile | null }) => {
      state.user = user;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      resetState(state);
    });

    builder.addCase(login.fulfilled, (state, action) => {
      const { loginUrl, projectLoginUrl, projectLogoutUrl, policy } = action.payload;
      state.policy = policy;
      state.redirectingToAuthServer = true;
      state.projectLoginUrl = projectLoginUrl;
      state.projectLogoutUrl = projectLogoutUrl;
      // TODO: moved out of reducer as the reducer should be pure. (need to verify persistReducer is still working)
      window.location.replace(loginUrl);
    });

    builder.addCase(handleCallback.pending, (state) => {
      const { policy } = state;
      resetState(state);
      state.policy = policy;
    });

    builder.addCase(handleCallback.fulfilled, (state, action) => {
      const { policy, b2cTokens } = action.payload;
      state.b2cTokens = b2cTokens;
      state.policy = policy;
    });

    builder.addCase(logout.pending, (state) => {
      state.idpLogoutUrl = null;
    });

    builder.addCase(logout.fulfilled, (state, action) => {
      const { logoutUrl, idpLogoutUrl } = action.payload;
      resetState(state);
      state.idpLogoutUrl = idpLogoutUrl;
      state.redirectingToAuthServer = true;
      // TODO: moved out of reducer as the reducer should be pure. (need to verify persistReducer is still working)
      window.location.replace(logoutUrl);
    });
  },
});

export { login, handleCallback, logout } from './async-thunks';

export const { setTokens, resetAuth, clearIdpLogoutUrl, setUser } = slice.actions;

const { reducer: unpersistedReducer } = slice;

export const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['redirectingToAuthServer'],
};

export const reducer = persistReducer(authPersistConfig, unpersistedReducer);

export type { ExtendedUserProfile } from './types';
