import { FC, MouseEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardMedia,
  Stack,
  Avatar,
  IconButton,
  Typography,
  CardContent,
  CardActionArea,
  Grid,
} from '@mui/material';
import {
  Lock as LockIcon,
  Info as InfoIcon,
  Settings as SettingsIcon,
  Pencil as PencilIcon,
  PersonManage as PersonManageIcon,
} from '@mott-macdonald/smi-react-ui-kit/icons';
import { ProjectMetadata } from '@mottmac-moata/identity-sdk';
import { useCloudinaryImage } from '@mott-macdonald/smi-react-ui-kit';

import { setTermsAndConditionsProps } from 'src/store/termsAndConditions';
import useAcceptTermsAndConditions from 'src/features/lobby/hooks/useAcceptTermsAndConditions';
import dayjs from 'src/utils/dayjsRelativeTime';
import { setDetailPanelProps } from 'src/store/detailPanel';
import { useProjectUrl } from 'src/hooks/useProjectUrl';

import { DEFAULT_TILE_IMAGE } from 'src/configs';
import { RouteBuilder } from 'src/router';
import { ProjectUserAPI } from 'src/services/api/ProjectUserAPI';
import { setMessage } from 'src/store/messages';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { useAppSelector } from 'src/store/useAppSelector';
import { AdminItemType } from 'src/features/admin/types';
import { useProjectEditModal } from '../ProjectEditModal/ProjectEditModalContext';

interface Props {
  project: ProjectMetadata;
  isDisabled?: boolean;
  userId?: string;
  isSuperAdmin?: boolean;
}

const ProjectTile: FC<Props> = ({ project, isDisabled = false, userId, isSuperAdmin = false }) => {
  const { mutateAsync: grantAdmin, isLoading: isGrantAdminLoading } = ProjectUserAPI.useGrantAdmin();
  const dispatch = useAppDispatch();
  const detailPanelProps = useAppSelector((state) => state.detailPanel.props);
  const { openProjectEditModal } = useProjectEditModal();

  const {
    imageUrl,
    productShortName,
    lastAccessedUTC,
    name,
    projectSource,
    projectId,
    tcAccepted: isTcAccepted,
    projectTermsAndConditionsUrl,
    isMember,
    isGroupAdmin,
    isProductAdmin,
  } = project;

  const checkedImageUrl = imageUrl?.includes('res.cloudinary.com') ? imageUrl : DEFAULT_TILE_IMAGE;
  const { imageRef, transformedImageUrl } = useCloudinaryImage<HTMLImageElement>({ imageUrl: checkedImageUrl });

  const stopRipplePropagation = (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>): void => {
    event.stopPropagation();
  };

  const { getProjectUrl } = useProjectUrl(projectSource, projectId, name, 0);

  // (is a Member, and also a GroupAdmin)
  const canAdmin = isGroupAdmin;
  // ("is a Member, but not a GroupAdmin" or "not a Member at all") and ("is a ProductAdmin or SuperAdmin")
  const canGrantAdmin = !canAdmin && (isProductAdmin || isSuperAdmin);

  const editButtonClickHandler = (event: MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    openProjectEditModal({ project });
  };

  const grantAdminButtonClickHandler = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (!userId) return;

      try {
        await grantAdmin({ projectId, userId });
        dispatch(
          setMessage({
            severity: 'success',
            content: 'You have been successfully added to the project.',
          })
        );
      } catch {
        dispatch(
          setMessage({
            severity: 'error',
            content: 'Something went wrong while adding you to the project. Please try again.',
          })
        );
      }
    },
    [userId, grantAdmin, projectId, dispatch]
  );

  const infoButtonClickHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      dispatch(
        setDetailPanelProps({
          id: projectId,
          name: name ?? '',
          resourceType: 'project',
        })
      );
    },
    [projectId, name, dispatch]
  );

  const { mutateAsync: acceptTermsAndConditions } = useAcceptTermsAndConditions();

  const handleOpenProject = async (): Promise<void> => {
    const projectUrl = await getProjectUrl();
    if (projectUrl !== undefined) window.open(projectUrl, '_self');
  };

  const handleOnTileClick = (): void => {
    if (!isMember && canGrantAdmin) {
      dispatch(
        setMessage({
          severity: 'info',
          title: 'You need to add yourself to this project first.',
          content: (
            <Stack display="inline-flex" direction="row" alignItems="center">
              Add yourself as a group admin by clicking
              <PersonManageIcon fontSize="small" color="inherit" sx={{ mx: 0.5 }} /> button.
            </Stack>
          ),
        })
      );
      return;
    }

    if (isTcAccepted) {
      handleOpenProject();
      return;
    }

    if (!projectTermsAndConditionsUrl) {
      return;
    }

    dispatch(
      setTermsAndConditionsProps({
        termsAndConditionsUrl: projectTermsAndConditionsUrl,
        onAccept: async () => {
          try {
            await acceptTermsAndConditions({ projectId });

            handleOpenProject();
          } catch {
            throw new Error('failed to accept T&C');
          }
        },
      })
    );
  };

  const isSelected =
    // This can be removed once we add more resourceTypes for detailPanelProps
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    detailPanelProps && detailPanelProps.resourceType === 'project' && detailPanelProps.id === projectId;

  const lastAccessedText = lastAccessedUTC ? `Opened ${dayjs(lastAccessedUTC).fromNow()}` : 'Not yet opened';

  return (
    <Card className="projectTile" sx={{ borderColor: isSelected ? 'primary.main' : '' }} data-testid="project-card">
      <CardActionArea
        disabled={isDisabled || isGrantAdminLoading}
        component="div"
        onClick={handleOnTileClick}
        aria-label="project"
        data-gtm-project-id={projectId}
        data-gtm-project-name={name}
        data-gtm-project-tile-id="container"
      >
        <CardMedia
          component="img"
          height="184"
          ref={imageRef}
          image={transformedImageUrl}
          alt={name}
          data-testid="cardImage"
        />
        <CardContent>
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            {productShortName !== '' && (
              <Grid item>
                <Avatar variant="square" sx={{ bgcolor: 'text.primary', width: 45, height: 45 }} aria-label={name}>
                  <Typography variant="h5">{productShortName}</Typography>
                </Avatar>
              </Grid>
            )}

            <Grid item zeroMinWidth sx={{ flex: 1 }}>
              <Typography variant="subtitle1" noWrap title={name}>
                {name}
              </Typography>
              <Typography
                variant="caption"
                sx={{ color: 'text.secondary', display: 'block' }}
                noWrap
                title={lastAccessedText}
              >
                {lastAccessedText}
              </Typography>
            </Grid>
            <Grid item>
              <Stack direction="row" alignItems="center">
                {canAdmin && (
                  <IconButton
                    title="Edit project settings"
                    size="small"
                    data-gtm-admin-edit-view={`edit-${projectId}`}
                    data-testid="edit-button"
                    onMouseDown={stopRipplePropagation}
                    onClick={editButtonClickHandler}
                  >
                    <PencilIcon fontSize="inherit" color="action" />
                  </IconButton>
                )}
                {canAdmin && (
                  <IconButton
                    title="Go to Admin view"
                    size="small"
                    data-gtm-admin-cog-view={`cog-${projectId}`}
                    data-testid="admin-cog"
                    component={Link}
                    to={RouteBuilder.admin(AdminItemType.Project, projectId)}
                    onMouseDown={stopRipplePropagation}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <SettingsIcon fontSize="inherit" color="action" />
                  </IconButton>
                )}
                {canGrantAdmin && (
                  <IconButton
                    disabled={isGrantAdminLoading}
                    title="Add myself to this project as a group admin"
                    size="small"
                    data-gtm-admin-user-view={`admin-management-${projectId}`}
                    data-testid="admin-management"
                    onMouseDown={stopRipplePropagation}
                    onClick={grantAdminButtonClickHandler}
                  >
                    <PersonManageIcon fontSize="inherit" color="action" />
                  </IconButton>
                )}
                <IconButton
                  title="Show project details"
                  disabled={isDisabled}
                  size="small"
                  aria-label="info"
                  onMouseDown={stopRipplePropagation}
                  onClick={infoButtonClickHandler}
                  data-gtm-project-tile-id="info-button"
                >
                  {isDisabled ? (
                    <LockIcon data-testid="project-tile-lock-icon" fontSize="inherit" />
                  ) : (
                    <InfoIcon fontSize="inherit" color="action" />
                  )}
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProjectTile;
