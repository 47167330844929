import { ProductGroupDTO, ProjectGroupDTO } from '@mottmac-moata/identity-sdk';
import { Pencil } from '@mott-macdonald/smi-react-ui-kit/icons';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FC, useCallback } from 'react';
import { useBoolean } from 'react-use';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { openAreYouSureConfirmDialog, openDiscardChangesConfirmDialog } from 'src/ui/Dialog';
import groupFormSchema from 'src/features/admin/components/GroupCreateModal/schema';
import { ItemsListManageModalButton } from 'src/features/admin/components/ItemsListManageModal/ItemsListManageModalButton';
import { ItemsManagementTextConstants } from 'src/features/admin/components/ItemsListManageModal/types';
import { AdminItemType } from 'src/features/admin/types';
import { useGroupUpdate } from 'src/features/admin/hooks/useGroupUpdate';
import { GroupManageActionsButton } from 'src/features/admin/components/GroupConfigurationHeader/components/GroupManageActionsButton';
import { useGroupUserController } from './hooks/useGroupUserController';

const itemsManagementConstants: ItemsManagementTextConstants = {
  title: 'Manage group members',
  membersTitle: 'Users in this group',
  actionBtnTooltipTitle: 'Add selected users into the group',
  removeBtnTooltipTitle: 'Remove selected users from the group',
  allItemsTitle: 'All users',
};

interface GroupConfigurationHeaderProps {
  itemType: AdminItemType;
  itemId: number;
  productId: number;
  group: ProjectGroupDTO | ProductGroupDTO;
}

export const GroupConfigurationHeader: FC<GroupConfigurationHeaderProps> = ({ itemType, itemId, group, productId }) => {
  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isDirty },
  } = useForm({ resolver: zodResolver(groupFormSchema), defaultValues: { name: group?.name ?? '' } });

  const GroupUsersController = useGroupUserController(itemType, itemId, group.groupId);

  const { updateName } = useGroupUpdate(itemType, itemId, group);

  const [isEditing, toggleIsEditing] = useBoolean(false);

  const onSaveNameEdit = handleSubmit((formData) => {
    return openAreYouSureConfirmDialog({
      warningText: "update the project group's name.",
      errorText: "updating the project group's name.",
      onConfirm: async () => {
        try {
          await updateName(formData.name);
        } catch (e) {
          setError('name', { message: 'There is already a project with this name' });
        }

        toggleIsEditing(false);
      },
    });
  });

  const onCancelNameEdit = useCallback(
    () =>
      openDiscardChangesConfirmDialog({
        onDiscard: () => {
          toggleIsEditing(false);
          reset({ name: group.name ?? '' });
        },
        isDirty,
      }),
    [group.name, isDirty, reset, toggleIsEditing]
  );

  return (
    <Grid container px={2}>
      <Grid item xs={12} md={8} alignItems="center">
        {!isEditing ? (
          <Stack direction="row" alignItems="center">
            <Typography
              component="h1"
              variant="h4"
              onClick={toggleIsEditing}
              sx={{ cursor: 'pointer', textOverflow: 'ellipsis', overflow: 'hidden', wordWrap: 'none' }}
            >
              {group.name}
            </Typography>
            <IconButton size="small" title="Edit group name" aria-label="Edit group name" onClick={toggleIsEditing}>
              <Pencil />
            </IconButton>
          </Stack>
        ) : (
          <form onSubmit={onSaveNameEdit}>
            <Stack direction="row" gap={1} alignItems="flex-start">
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ width: 250 }}
                    label="Group name"
                    autoFocus
                    required
                    error={!!errors.name}
                    helperText={errors.name ? errors.name.message : undefined}
                  />
                )}
              />
              <Button variant="outlined" onClick={onCancelNameEdit}>
                Cancel
              </Button>
              <Button disabled={!isDirty || !!errors.name} onClick={onSaveNameEdit}>
                Save
              </Button>
            </Stack>
          </form>
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container spacing={1} direction={{ xs: 'column', sm: 'row' }}>
          <Grid item xs={6}>
            <ItemsListManageModalButton
              title="Manage Users"
              ItemsController={GroupUsersController}
              constants={itemsManagementConstants}
            />
          </Grid>
          <Grid item xs={6}>
            <GroupManageActionsButton
              itemType={itemType}
              productId={productId}
              actionIds={group.actions}
              itemId={itemId}
              groupId={group.groupId}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
