import { SerializedError } from '@reduxjs/toolkit';
import { ProductGroupDTO, ProjectGroupBaseDTO } from '@mottmac-moata/identity-sdk';
import { AdminItemType } from 'src/features/admin/types';
import { ProjectGroupAPI } from 'src/services/api/ProjectGroupAPI';
import { ProductGroupAPI } from 'src/services/api/ProductGroupAPI';

export const useGroupsById = <
  T extends AdminItemType,
  G = T extends AdminItemType.Project
    ? ProjectGroupBaseDTO[]
    : T extends AdminItemType.Product
      ? ProductGroupDTO[]
      : never,
>(
  type: T,
  itemId: number
): {
  groups?: G;
  isLoading: boolean;
  error?: SerializedError;
} => {
  const {
    data: projectGroups,
    isLoading: isProjectGroupsLoading,
    error: projectGroupsError,
  } = ProjectGroupAPI.useGetByProjectId(itemId, { enabled: type === AdminItemType.Project });
  const {
    data: productGroups,
    isLoading: isProductGroupsLoading,
    error: productGroupError,
  } = ProductGroupAPI.useGetByProductId(itemId, { enabled: type === AdminItemType.Product });

  return {
    groups: (type === AdminItemType.Project ? projectGroups : productGroups) as G | undefined,
    isLoading: isProjectGroupsLoading || isProductGroupsLoading,
    error: projectGroupsError || productGroupError || undefined,
  };
};
