import { SerializedError } from '@reduxjs/toolkit';
import { UseMutationResult, useMutation } from 'react-query';
import useAuth from 'src/hooks/useAuth';
import { authorisedIdentityApiClient } from 'src/services/api/identityApiClient';

type Props = {
  projectId: number;
};

const useAcceptTermsAndConditions = (): UseMutationResult<void, SerializedError, Props> => {
  const { getAccessToken } = useAuth();

  return useMutation(async ({ projectId }: Props) =>
    authorisedIdentityApiClient(await getAccessToken()).Project.acceptTermsAndConditions({
      projectId,
    })
  );
};

export default useAcceptTermsAndConditions;
