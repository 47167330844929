import { Action } from '@mottmac-moata/identity-sdk';
import { AdminItemType } from 'src/features/admin/types';
import { ProductActionAPI } from 'src/services/api/ProductActionAPI';
import { ProjectActionAPI } from 'src/services/api/ProjectActionAPI';

export const useGetAllActions = (
  itemType: AdminItemType,
  itemId: number
): { actions?: Action[]; isLoading: boolean; isError: boolean } => {
  const {
    data: projectActions,
    isLoading: isLoadingProjectActions,
    isError: isErrorProjectActions,
  } = ProjectActionAPI.useGetAll(itemId, { enabled: itemType === AdminItemType.Project });

  const {
    data: productActions,
    isLoading: isLoadingProductActions,
    isError: isErrorProductActions,
  } = ProductActionAPI.useGetAll(itemId, { enabled: itemType === AdminItemType.Product });

  return {
    actions: itemType === AdminItemType.Project ? projectActions : productActions,
    isLoading: isLoadingProductActions || isLoadingProjectActions,
    isError: isErrorProductActions || isErrorProjectActions,
  };
};
