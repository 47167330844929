import { isNonNullable } from 'src/utils/filters';

const ParamPlaceHolder = {
  Token: '##TOKEN##',
  IdentityId: '##IDENTITYID##',
  ProjectName: '##PROJECTNAME##',
  ProductId: '##PRODUCTID##',
  ResourceName: '##RESOURCENAME##',
} as const;

export const buildProjectUrl = (
  projectSourceUrl: string,
  {
    token,
    projectId,
    projectName,
    productId,
    resourceName,
  }: {
    token?: string;
    projectId?: number;
    projectName?: string;
    productId?: number;
    resourceName?: string;
  }
): string =>
  projectSourceUrl
    .replaceAll(ParamPlaceHolder.Token, token || ParamPlaceHolder.Token)
    .replaceAll(ParamPlaceHolder.IdentityId, isNonNullable(projectId) ? String(projectId) : ParamPlaceHolder.IdentityId)
    .replaceAll(ParamPlaceHolder.ProjectName, projectName || ParamPlaceHolder.ProjectName)
    .replaceAll(ParamPlaceHolder.ProductId, isNonNullable(productId) ? String(productId) : ParamPlaceHolder.ProductId)
    .replaceAll(ParamPlaceHolder.ResourceName, resourceName || ParamPlaceHolder.ResourceName);
