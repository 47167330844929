import { FC, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Modal from 'src/ui/Modal';
import { ProjectGroupAPI } from 'src/services/api/ProjectGroupAPI';
import { setMessage } from 'src/store/messages';
import { ProjectUserAPI } from 'src/services/api/ProjectUserAPI';
import { isInternalEmail } from 'src/utils/isInternalEmail';
import { useAppDispatch } from 'src/store/useAppDispatch';
import { useAppSelector } from 'src/store/useAppSelector';
import { ProjectAPI } from 'src/services/api/ProjectAPI';
import { UserEmailsStep } from './UserEmailsStep';
import { GroupsStep } from './GroupsStep';
import { getDefaultInviteMessage, InvitationMessageStep } from './InvitationMessageStep';
import { isEmailValueValid, RESTRICTED_EMAIL_ERROR_MESSAGE } from './helpers';

const STEPS = ['Select groups', 'Add user emails', 'Write invitation message'];

interface ProjectUserInviteGuideProps {
  open: boolean;
  onClose: () => void;
  projectId: number;
}
export const ProjectUserInviteGuide: FC<ProjectUserInviteGuideProps> = ({ projectId, open = false, onClose }) => {
  const dispatch = useAppDispatch();

  const {
    data: groups,
    error: groupsError,
    isLoading: isGroupsLoading,
  } = ProjectGroupAPI.useGetByProjectId(Number(projectId));
  const {
    mutateAsync: sendInvitation,
    error: sendInvitationError,
    isLoading: isSendInvitationLoading,
  } = ProjectUserAPI.useInviteUsers();
  const user = useAppSelector((state) => state.auth.user);
  const { data: project } = ProjectAPI.useGetById(projectId);
  const { data: inviteTemplate } = ProjectAPI.useGetInviteTemplate(projectId);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);

  const [emailsList, setEmailsList] = useState<string[]>([]);
  const [emailText, setEmailText] = useState<string>('');
  const [emailError, setEmailError] = useState<string>();

  const [invitationMessageError, setInvitationMessageError] = useState<string>();
  const [invitationMessage, setInvitationMessage] = useState<string>('');
  const [isDefaultMessage, setIsDefaultMessage] = useState<boolean>(true);
  const defaultMessage = useMemo(
    () => getDefaultInviteMessage(selectedGroupIds, inviteTemplate?.content, groups, project?.name, user?.displayName),
    [inviteTemplate, groups, project, selectedGroupIds, user?.displayName]
  );
  const isProjectInternal = project?.isInternal || false;

  const onInvitationMessageChange = (message: string): void => {
    setInvitationMessage(message);
    setIsDefaultMessage(false);
  };

  const handleNext = async (): Promise<void> => {
    if (activeStep === 1 && (emailText?.length > 0 || emailsList.length === 0)) {
      if (!isEmailValueValid(emailText)) {
        setEmailError('Please input a valid email address');
        return;
      }

      if (isProjectInternal && !isInternalEmail(emailText)) {
        setEmailError(RESTRICTED_EMAIL_ERROR_MESSAGE);
        return;
      }
    }

    if (activeStep === 2) {
      if (!invitationMessage && !isDefaultMessage) {
        setInvitationMessageError('Please add an invitation message');
        return;
      }
      try {
        const emails = [...emailsList];
        if (emailText?.length > 0) emails.push(emailText);
        await sendInvitation({
          projectId,
          payload: {
            groupIds: selectedGroupIds,
            inviteEmailText: isDefaultMessage ? defaultMessage : invitationMessage,
            userEmails: emails,
          },
        });
        dispatch(setMessage({ severity: 'success', content: `Invitation has been sent successfully` }));
        onClose();
        return;
      } catch (e) {
        return;
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (): void => {
    setEmailError(undefined);
    setInvitationMessageError(undefined);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Modal
      closable
      message={
        groupsError || sendInvitationError
          ? {
              content: 'Sorry, something went wrong. Please try again later',
              severity: 'error',
            }
          : undefined
      }
      open={open}
      onCancel={onClose}
      title="Invite Users"
    >
      <Modal.Content>
        <Box sx={{ maxWidth: '500px' }}>
          <Stepper activeStep={activeStep}>
            {STEPS.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <Stack display="flex" flexDirection="column" justifyContent="space-between">
            <Stack gap={3} display="flex" pt={4}>
              {activeStep === 0 && (
                <GroupsStep
                  groups={groups}
                  selectedGroupIds={selectedGroupIds}
                  onSelectedGroupIdsChange={setSelectedGroupIds}
                />
              )}
              {activeStep === 1 && (
                <UserEmailsStep
                  email={emailText}
                  emailList={emailsList}
                  onChange={setEmailText}
                  onListUpdate={setEmailsList}
                  error={emailError}
                  onError={setEmailError}
                  isProjectInternal={isProjectInternal}
                />
              )}
              {activeStep === 2 && (
                <InvitationMessageStep
                  disabled={isSendInvitationLoading}
                  value={isDefaultMessage ? defaultMessage : invitationMessage}
                  onChange={onInvitationMessageChange}
                  error={invitationMessageError}
                  onError={setInvitationMessageError}
                />
              )}
            </Stack>
            <Box display="flex" justifyContent="space-between" pt={2}>
              {activeStep !== 0 && (
                <Button variant="outlined" disabled={activeStep === 2 && isSendInvitationLoading} onClick={handleBack}>
                  Back
                </Button>
              )}
              <Box />
              <LoadingButton
                variant="contained"
                disabled={activeStep === 0 && selectedGroupIds.length === 0}
                onClick={handleNext}
                loading={isSendInvitationLoading || isGroupsLoading}
              >
                {activeStep === STEPS.length - 1 ? 'Send Invitation' : 'Next'}
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Modal.Content>
    </Modal>
  );
};
