/**
 * @deprecated `id_token` will be replaced by `mm_access_token` in the future.
 */
export const CallbackRedirectIdTokenParam = {
  // when we click on a project tile,
  // the param name for the token is configured inside the `projectSource` url template by the creator.
  //
  // looked through exiting projects in the DB, and found that there are names like: `token`, `id_token`, `accessToken`.
  // `id_token` is the most widely used one. especially among Moata Sub-Apps projects.
  //
  // so, to simplify the implementation,
  // we choose the `id_token` to make it compatible with the most Moata Sub-Apps projects,
  // because Moata Sub-Apps will be target audience for the new SSO Auth Flow.
  //
  // note:
  //   the existing logic of the `IdentityCallbackHandler` from `react-moata-identity-integration`
  //   is NOT using this param.
  //   it gets new tokens with the `mm_refresh_token` and `mm_policy` params.
  /**
   * @deprecated `id_token` will be replaced by `mm_access_token` in the future. Check out {@link CallbackRedirectAuthParam.AccessToken}
   */
  IdToken: 'id_token',
} as const;

export const CallbackRedirectAuthParam = {
  AccessToken: 'mm_access_token',
  RefreshToken: 'mm_refresh_token',
  Policy: 'mm_policy',
  LoginUrl: 'mm_login',
  LogoutUrl: 'mm_logout',
} as const;

export const CallbackRedirectStateParam = {
  State: 'mm_state',
  ProjectId: 'mm_project_id',
} as const;
