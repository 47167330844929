import chmln from '@chamaeleonidae/chmln';
import { StringAlias } from '@mottmac-moata/identity-sdk/dist/types/StringAlias';
import md5 from 'md5';

interface IdentifyChameleonParams {
  user: { id: StringAlias.UUID; email: string; name?: string };
}

export const identifyChameleon = ({ user }: IdentifyChameleonParams): void => {
  // app.moata.com uses the email hash as the user unique identifier for some reason,
  // we keep the same logic here for now.
  const emailHash = md5(user.email);

  // TODO: use the Identity User UUID instead of the email hash
  // Need to coordinate with the app.moata.com to do the same on their side.
  chmln.identify(emailHash, {
    // do not give Chameleon any extra user info
  });
};
