import type { ProjectDto } from '@mottmac-moata/identity-sdk';
import dayjs from 'dayjs';
import { getLocalCmsUrl } from 'src/utils/localCMS';
import { TermsAndConditionsType } from 'src/features/lobby/components/ProjectEditModal/constants';

export const getDefaultValues = (initialProject?: ProjectDto, initialProductId?: number): Partial<ProjectDto> => ({
  name: initialProject?.name,
  productId: initialProductId ?? initialProject?.productId,
  subHeader: initialProject?.subHeader,
  usageType: initialProject?.usageType,
  contractId: initialProject?.contractId,
  validTill:
    // to prevent bad date string `0001-01-01T00:00:00` from API
    initialProject?.validTillUtc &&
    dayjs(initialProject.validTillUtc).isValid() &&
    dayjs(initialProject.validTillUtc).isAfter(2000, 'y')
      ? initialProject.validTillUtc
      : undefined,
  region: initialProject?.region,
  imageUrl: initialProject?.imageUrl,
  projectSource: initialProject?.projectSource,
  projectSummaryUrl: initialProject?.projectSummaryUrl,
  newsListUrl: initialProject?.newsListUrl,
  projectTermsAndConditionsUrl:
    initialProject?.projectTermsAndConditionsUrl ?? getLocalCmsUrl(`/tcs/${TermsAndConditionsType.Default}.json`),
});
